import Link from "./link";
import React, { Component } from "react";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";
import Icon from "./icon";
import theme from "../styles/theme.module.scss";
import styles from "./contact-intro.module.scss";

function ContactIntro(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT6} ${theme.borderBottom}`}
    >
      <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.gmmain} ${theme.mB3}`}>
        {props.careersHeadline}
      </p>
      <h2
        className={`${theme.h5_founders} ${theme.gds1} ${theme.gde4} ${theme.gmmain} ${theme.mB6}`}
      >
        {props.careersTitle}
      </h2>
      <PortableText
        className={`${theme.portableText} ${theme.h2_tiempos} ${theme.gds5} ${theme.gde12} ${theme.gmmain}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawCareersCopyPortable}
        serializers={serializers}
      />
      <ul className={`${styles.subPagesLinks} ${theme.gdfull} ${theme.gmfull} ${theme.mT1}`}>
        {props.careersPostings &&
          props.careersPostings.map((node, index) => (
            <li
              className={` ${theme.borderTop} ${theme.gdcont} ${theme.gmcont}  ${theme.h2_founders}`}
            >
              <Link
                to={node.url}
                className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont} ${theme.gmmain} ${theme.fmcont} ${theme.fmvcenter} ${theme.fmnowrap}`}
              >
                {node.role} <Icon className={`${styles.icon}`} symbol="arrowRight" />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ContactIntro;
