import React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import Intro from "../../components/contact-careers";

export const query = graphql`
  query CareersInquiriesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
    }
    careers: sanityContact {
      _rawAddressColumn1
      _rawAddressColumn2
      _rawAddressColumn3
      careersHeadline
      careersTitle
      _rawCareersCopyPortable(resolveReferences: { maxDepth: 10 })
      careersPostings {
        role
        url
      }
    }
  }
`;

const CareersPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const careers = (data || {}).careers;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Careers" description={site.description} keywords={site.keywords} />
      <Container>
        <h1 hidden>Careers</h1>
        <Intro {...careers} />
      </Container>
    </Layout>
  );
};

export default CareersPage;
